.listSelect {
  display: inline-flex;
  color: var(--primary-color-one);
}

.listSelectVertical {
  flex-direction: column;
}

.listSelectHorizontal {
  flex-direction: row;
  border: 3px solid var(--primary-color-one);
  border-radius: 0.25rem;
  background: var(--primary-color-three);
  margin: 1.5rem 0;
}

.listSelectButton {
  margin: 1rem 0;
  cursor: pointer;
}

.listSelectButtonVertical {
  /* border-bottom: 1px solid var(--primary-color-one); */
}

.listSelectButtonVertical:last-child {
  /* border-bottom: 0; */
}

.listSelectButtonHorizontal {
  flex: 1;
  text-align: center;
  margin: 0;
  border: 0;
  border-radius: 0;
  border-right: 1px solid var(--primary-color-one);
}

.listSelectButtonHorizontal:last-child {
  border-right: 0;
}

.iconListSelect {
}

.iconListSelectButton {
  display: inline-flex;
  flex-direction: column;
  background: var(--primary-color-three);
  padding: 0.5rem;
  width: 12rem;
  height: 15rem;
  border: 1px solid var(--primary-color-one);
  margin: 1rem;
  border-radius: 0.25rem;
  overflow: hidden;
  cursor: pointer;
}

.iconListSelectButtonSelected {
  background: var(--primary-color-five);
}

.iconListSelectButtonIcon {
  display: flex;
  background: white;
  border-radius: inherit;
  height: 8rem;
}

.iconListSelectButtonIcon > img {
  display: block;
  margin: auto;
  height: 6rem;
  max-width: 90%;
}

.iconListSelectButtonLabel {
  display: flex;
  flex: 1;
  color: var(--primary-color-six);
  font-size: 1.25em;
}

.iconListSelectButtonLabel > span {
  margin: auto;
  text-align: center;
}

@media (max-width: 500px) {
  .iconListSelectButton {
    width: 10rem;
    margin: 0.5rem;
  }
}

@media (max-width: 700px) {
  .iconListSelectButton {
    width: 10rem;
  }
}
