@import-normalize; /* bring in normalize.css styles */

:root {
  --font-size-base: 18px;
  --font-color: #454545;
  --site-max-width: 50rem;
  --primary-color-one: #00bbb4;
  --primary-color-two: #256867;
  --primary-color-three: #def2f0;
  --primary-color-four: #abd7d7;
  --primary-color-five: #aae0d3;
  --primary-color-six: #03bbb4;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  user-select: none;
}

html {
  font-size: var(--font-size-base);
  color: var(--font-color);
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

#root {
  width: 100%;
  height: 100%;
}

section {
  display: flex;
  width: 100%;
  max-width: var(--site-max-width);
  min-height: 100%;
  margin: 0 auto;
  padding: 3rem 3rem;
}

section > article {
  width: 100%;
  margin: auto;
}

p {
  font-size: 1.25em;
  line-height: 1.5em;
}

input,
select,
textarea {
  user-select: initial;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

button:active,
button:focus {
  outline: 1px dashed black;
}

@media (max-width: 500px) {
  html {
    font-size: calc(var(--font-size-base) * 0.8);
  }

  section {
    padding: 1rem 1rem;
  }
}

@media (max-width: 700px) {
  section {
    padding: 2rem 2rem;
  }
}
