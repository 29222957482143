.questionNumber {
  display: inline-flex;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
  font-size: 0.8em;
  border-radius: 50%;
  background: var(--primary-color-one);
}

.questionNumber > span {
  margin: auto;
  color: white;
}
