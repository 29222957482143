.activeItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.questions {
  flex: 1;
  overflow-y: scroll;
}
