.inputElement {
  font-size: 1em;
  line-height: 2em;
  padding: 0.25rem;
  color: var(--primary-color-one);
  border: 0;
  border-bottom: 1px solid var(--primary-color-four);
  border-radius: 0;
  width: 100%;
}
