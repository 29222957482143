.checkboxes {
  width: 11rem;
}

.disagreeMessage {
  visibility: hidden;
  color: red;
}

.disagreeMessageVisible {
  visibility: visible;
}
