.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color-one);
  color: white;
  font-family: inherit;
  font-size: 1em;
  white-space: nowrap;
  border: 0;
  transition: 0.4s;
  border-radius: 3rem;
  padding: 0.5rem 2rem;
  margin: 0 0.5rem;
  cursor: pointer;
  user-select: none;
}

.button:hover {
  opacity: 0.8;
}

.buttonInverted {
  background-color: white;
  color: black;
}

.buttonDisabled {
  opacity: 0.4 !important;
  cursor: not-allowed;
  /* pointer-events: none; */
}
