.numericalSelect {
  width: 100%;
}

.labels {
  display: flex;
  flex-direction: row;
}

.labels > div {
  flex: 1;
  color: var(--primary-color-one);
  font-size: 0.8em;
  padding: 0 0.25rem;
}

.labels > div:last-child {
  text-align: right;
}
