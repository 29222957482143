.questionCopy {
  margin-top: 4rem;
}

.questionCopy:first-child {
  margin-top: 0;
}

.title {
  font-size: 1.25em;
  line-height: 1.5em;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.title > * {
  vertical-align: middle;
}

.questionNumber {
  margin-right: 1rem;
}

.description {
  font-size: 1.25em;
  line-height: 1.5em;
  opacity: 0.75;
  margin-bottom: 1rem;
}
