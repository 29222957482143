.checkbox {
  display: flex;
  flex-direction: row;
  border: 3px solid var(--primary-color-one);
  border-radius: 4px;
  background: var(--primary-color-three);
  color: var(--primary-color-one);
  white-space: nowrap;
  height: 3rem;
  margin: 1.5rem 0;
  align-items: center;
  transition: 0.4s;
  cursor: pointer;
  user-select: none;
}

.checkboxChecked {
  background: var(--primary-color-five);
}

.squareContainer {
  display: flex;
  padding: 0 1rem;
}

.hideCheckboxes {
  display: none;
}

.square {
  margin: auto;
  height: 1.4rem;
  width: 1.4rem;
  background-color: white;
  border: 1px solid var(--primary-color-one);
}

.squareChecked {
  background: var(--primary-color-one);
}

.label {
  flex: 1;
  font-size: 1.2em;
  padding-right: 1.5rem;
}

.hideCheckboxes ~ .label {
  padding: 0 1rem;
}
